<template>
	<div class="box col-span-6" >
		<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-1/2 mr-12">
					<div class="mb-3 flex">
						<p class="font-bold text-lg form-label mb-0 mr-2">Attachment email: </p>
						<ToggleSlide
							class="w-min pointer-events-none"
							:key="attachEmail"
							:bool="attachEmail"
							:disabled="true"
							:renew-bool="true"
						/>
					</div>
					<div class="mb-3 flex">
						<p class="font-bold text-lg form-label mb-0 mr-2">Link email: </p>
						<ToggleSlide
							class="w-min pointer-events-none"
							:key="linkEmail"
							:bool="linkEmail"
							:disabled="true"
							:renew-bool="true"
						/>
					</div>
					<div class="mb-3 flex">
						<p class="font-bold text-lg form-label mb-0 mr-2">Edi: </p>
						<ToggleSlide
							class="w-min pointer-events-none"
							:key="edi"
							:bool="edi"
							:disabled="true"
							:renew-bool="true"
						/>
					</div>
					<div class="mb-3 flex">
						<p class="font-bold text-lg form-label mb-0 mr-2">Print: </p>
						<ToggleSlide
							class="w-min pointer-events-none"
							:key="print"
							:bool="print"
							:disabled="true"
							:renew-bool="true"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script >
import ToggleSlide from "@/components/ToggleSlide.vue";
import {defineComponent} from "vue";

export default defineComponent({
	components: {ToggleSlide},
	data(){
		return {
			attachEmail: !!this.$store.state.activeLE.channelsConfig?.attachEmail?.enabled ? this.$store.state.activeLE.channelsConfig.attachEmail.enabled : false,
			linkEmail: !!this.$store.state.activeLE.channelsConfig?.linkEmail?.enabled ? this.$store.state.activeLE.channelsConfig.linkEmail.enabled : false,
			edi: !!this.$store.state.activeLE.channelsConfig?.edi?.enabled ? this.$store.state.activeLE.channelsConfig.edi.enabled : false,
			print: !!this.$store.state.activeLE.channelsConfig?.print?.enabled ? this.$store.state.activeLE.channelsConfig.print.enabled : false
		}
	},
	watch: {
		"$store.state.activeLE": {
			handler(){
				this.attachEmail = !!this.$store.state.activeLE.channelsConfig?.attachEmail?.enabled ? this.$store.state.activeLE.channelsConfig.attachEmail.enabled : false
				this.linkEmail = !!this.$store.state.activeLE.channelsConfig?.linkEmail?.enabled ? this.$store.state.activeLE.channelsConfig.linkEmail.enabled : false
				this.edi = !!this.$store.state.activeLE.channelsConfig?.edi?.enabled ? this.$store.state.activeLE.channelsConfig.edi.enabled : false
				this.print = !!this.$store.state.activeLE.channelsConfig?.print?.enabled ? this.$store.state.activeLE.channelsConfig.print.enabled : false
			},
			deep: true
		}
	}
})

</script>