<template>
	<pop-up-window
		:show="show"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit Channels</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-1/2 mr-12">
					<div class="flex flex-row flex-nowrap">
							<label class="font-bold text-lg form-label mb-0 mr-2">Attach email: </label>
							<ToggleSlide
								class="w-min"
								:bool="attachEmail"
								@update-value="attachEmail = !attachEmail"
							/>
					</div>
					<div class="flex flex-row flex-nowrap">
							<label class="font-bold text-lg form-label mb-0 mr-2">Link email: </label>
							<ToggleSlide
								class="w-min"
								:bool="linkEmail"
								@update-value="linkEmail = !linkEmail"
							/>
					</div>
					<div class="flex flex-row flex-nowrap">
						<label class="font-bold text-lg form-label mb-0 mr-2">Edi: </label>
						<ToggleSlide
							class="w-min"
							:bool="edi"
							@update-value="updateChannel('edi')"
						/>
					</div>
					<div class="flex flex-row flex-nowrap">
						<label class="font-bold text-lg form-label mb-0 mr-2">Print: </label>
						<ToggleSlide
							class="w-min"
							:bool="print"
							:renew-bool="true"
							@update-value="updateChannel('print')"
						/>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="cancel">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>
<script>

import ToggleSlide from "@/components/ToggleSlide.vue"
import PopUpWindow from "../PopUp.vue"

export default {
	name: "ViewIngestion",
	components: {ToggleSlide, PopUpWindow},
	props: ["show"],
	emits: ["close"],
	data() {
		return {
			attachEmail: false,
			linkEmail: false,
			edi: false,
			print: false
		}
	},
	mounted() {
		this.defaultValues()
	},
	methods: {
		updateChannel(channel) {
			this[channel] = !this[channel]
		},
		defaultValues(){
			this.attachEmail = !!this.$store.state.activeLE.channelsConfig?.attachEmail?.enabled ? this.$store.state.activeLE.channelsConfig.attachEmail.enabled : false
			this.linkEmail = !!this.$store.state.activeLE.channelsConfig?.linkEmail?.enabled ? this.$store.state.activeLE.channelsConfig.linkEmail.enabled : false
			this.edi = !!this.$store.state.activeLE.channelsConfig?.edi?.enabled ? this.$store.state.activeLE.channelsConfig.edi.enabled : false
			this.print = !!this.$store.state.activeLE.channelsConfig?.print?.enabled ? this.$store.state.activeLE.channelsConfig.print.enabled : false
		},
		update() {
			let payload = {}
			if (this.attachEmail !== false) payload.attachEmail = {enabled: this.attachEmail}
			if (this.linkEmail !== false) payload.linkEmail = {enabled: this.linkEmail}
			if (this.edi !== false) payload.edi = {enabled: this.edi}
			if (this.print !== false) payload.print = {enabled: this.print}

			this.$store.dispatch('EditLegalEntityChannels', payload)
			this.$emit('close')
		},
		cancel() {
			this.defaultValues()
			this.$emit('close')
		}
	}
}
</script>
